<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <!-- top header start -->
    <header>
        <a href="#"><img src="assets/images/landing-page/aanantya-logo.png" alt=""></a>
    </header>
    <!-- top header end -->

        <!-- Landing Page body start -->
        <div class="power-modern-sanskrit">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-7 col-md-7 col-sm-12 col-12">
                        <div class="power-moder-lft-sans">
                            <h2>{{landingtData.topTitle}}</h2>
                            <h4>{{landingtData.topSubTitle}}</h4>
                            <span v-html="landingtData.topContent" class="topContentCustomClass"></span>
                            <router-link to="/signup" class="landing-signup-btn">
                                Sign Up Now
                            </router-link>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-12 col-12">
                        <div class="power-moder-rgt-sans">
                            <img src="assets/images/landing-page/power-sanskrit-chat.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Why aanatya start -->
        <div class="why-aanatya">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-7 col-md-7 col-sm-12 col-12 p-0">
                        <div class="why-aanatya-lft">
                            <!-- <h2>Why aanantya.co?</h2>
                            <p>Sanskrit is one of the oldest and most beautiful languages in the world</p>
                            <p>The knowledge and wisdom contained in Samskrit are eternally and universally applicable</p>
                            <p>Presently, the treasures stored in Samskrit are locked up and the key has been thrown away</p> -->
                            <span v-html="landingtData.secondContentLeft"></span>
                            <router-link to="/signup" class="landing-signup-btn">
                                Sign Up Now
                            </router-link>
                        </div>
                    </div>
                    <div class="col-xl-5 col-md-5 col-sm-12 col-12">
                        <div class="why-aanatya-rgt">
                            <ul class="list-inline">
                                <li v-for="(info, index) in landingtData.secondContentRight" :key="index">
                                    <i class="fa-solid fa-book"></i> {{ info }}
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Why aanatya end -->
        <!-- How learn sanskrit start -->
        <div class="how-learn-sanskrit">
            <div class="container">
                <h2>How you could learn Samskrit on OPENAI versus How you would learn Samskrit on Aanantya.co</h2>
                <p>Our AI tools are meticulously prompt engineered to provide high-quality, actionable feedback on all aspects of research writing.</p>
                <div class="row">
                    <div class="col-xl-4 col-md-4 col-sm-4 col-12">
                        <div class="how-learn-sans-thumb learn-left">
                            <h3>Ai generated text without expert prompting</h3>
                            <span><i class="fa-solid fa-angles-down"></i></span>
                            <div class="how-learn-sans-thumb-inner">
                                <img src="assets/images/landing-page/tab-frame.png" alt="">
                                <img src="assets/images/landing-page/frame-1.jpg" alt="" class="learn-frame-bg">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-4 col-12">
                        <div class="how-learn-sans-thumb learn-rgt">
                            <h3>Ai generated text without expert prompting</h3>
                            <span><i class="fa-solid fa-angles-down"></i></span>
                            <div class="how-learn-sans-thumb-inner">
                                <img src="assets/images/landing-page/tab-frame.png" alt="">
                                <img src="assets/images/landing-page/frame-2.jpg" alt="" class="learn-frame-bg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="how-learn-btn">
                    <router-link to="/signup" class="landing-signup-btn">
                        Sign Up Now
                    </router-link>
                </div>
            </div>
        </div>
        <!-- How learn sanskrit end -->
        <!-- Video user start -->
        <div class="video-user">
            <div class="container">
                <h2>Videos of how someone uses Aanantya.co</h2>
                <div class="row">
                    <div class="col-xl-4 col-md-4 col-sm-4 col-12">
                        <div class="video-user-iframe">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/V7N2CXdSqks?si=E7xHMegFrQhHGRTN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-4 col-12">
                        <div class="video-user-iframe">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/V7N2CXdSqks?si=E7xHMegFrQhHGRTN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div class="how-learn-btn">
                    <router-link to="/signup" class="landing-signup-btn">
                        Sign Up Now
                    </router-link>
                </div>
            </div>
        </div>
        <!-- Video user user -->
        <!-- How it works start -->
            <div class="how-it-works">
                <div class="container">
                    <h2>How It Works</h2>
                    <div class="row">
                        <div class="col-xl-4 col-md-4 col-sm-12 col-12">
                            <div class="how-works-iner">
                                <span><i class="fa-solid fa-user-plus"></i></span>
                                <h5>Sign up and try us out for free for seven days</h5>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12 col-12">
                            <div class="how-works-iner">
                                <span><i class="fa-solid fa-message"></i></span>
                                <h5>Spend only fifteen minutes a day. Engage in warm conversations in Samskrit, while learning to read Samskrit fluently. Even if you don't speak English.</h5>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12 col-12">
                            <div class="how-works-iner">
                                <span><i class="fa-solid fa-head-side-virus"></i></span>
                                <h5>If your Samskrit reading fluency improves and you enjoy the experience, do tell us and just continue.</h5>
                            </div>
                        </div>
                    </div>
                    <div class="how-learn-btn">
                        <router-link to="/signup" class="landing-signup-btn">
                          Sign Up Now
                        </router-link>
                    </div>
                </div>
            </div>
        <!-- How it works end -->
        <!-- Pricing plane start -->
            <div class="pricing-plan">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-md-6 col-sm-12">
                            <div class="pricing-plan-inner">
                                <p>Please click here to see the very affordable pricing plan. You will go through registration to see pricing. </p>
                                <div class="how-learn-btn">
                                    <router-link to="/signup" class="landing-signup-btn">
                                        Sign Up Now
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- Pricing plane end -->
        <!-- Benefit for everyone start -->
        <div class="benefit-everyone">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-12 col-12">
                        <div class="benefit-left-everyone">
                            <h2>Benefits for everyone.</h2>
                            <p>Tell us how you can add value to our students of Samskrit, by sharing your subject-matter-expertise with us</p>
                            <div class="how-learn-btn">
                                <router-link to="/signup" class="landing-signup-btn">
                                    Sign Up Now
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-12 col-12">
                        <img src="assets/images/landing-page/benefit-everyone-upper.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- Benefit for everyone end -->
        <!-- Footer start -->
        <footer class="footer-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-12 col-12">
                        <div class="ftr-logo">
                            <img src="assets/images/landing-page/aanantya-logo.png" alt="">
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-3 col-sm-12 col-12">
                        <div class="footer-list">
                            <ul class="list-inline">
                                <li> <router-link to="/our-commitment">Our Commitment</router-link></li>
                                <li><router-link to="/modern-linguistic-techniques">Modern Linguistic Techniques</router-link></li>
                                <li><router-link to="/faq">FAQ</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-3 col-sm-12 col-12">
                        <div class="footer-list">
                            <ul class="list-inline">
                                <li><router-link to="/terms-condition">Terms & Conditions and Privacy Policy</router-link></li>
                                <li><router-link to="/about-us">About Us</router-link></li>
                                <li><router-link to="/contact-us">Contact Us</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-last">
                <p>Copyright 2023 All rights reserved aanantya</p>
            </div>
        </footer>
        <!-- Footer end -->
        <beforeLoginChatbot></beforeLoginChatbot>
    <!-- Landing Page body end -->
    </template>
    
    <script>
    
    import { API } from "@/api/api";
    import beforeLoginChatbot from '../views/beforeLoginChatbot.vue';
    
    export default {
        components: {
            // eslint-disable-next-line vue/no-unused-components
            beforeLoginChatbot
        },
      data() {
        return {
            landingtData: {
                topTitle: '',
                topSubTitle: '',
                topContent: '',
                secondContentLeft: '',
                secondContentRight: []
            },
        };
      },

      created() {
    this.getContent();
  },
      
      methods: {
        getContent(){
            API.get("generic/landingpage")
      .then(response => {
                this.landingtData = response.data.data;
                console.log(this.landingtData, "After got contentData");
            }).catch(error => {
                  console.error("fail", error);
                  });
        }
      },
    };
    </script>
    