<template>
    <div class="lds-ripple-content" v-if="isLoading"><div class="lds-ripple"><div></div><div></div></div></div>
    <section class="main-page subscription-page">
        <div class="container">
            <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
            <div class="row sign-in-form">
                
                <div class="col-xl-4 col-md-4 col-sm-12 col-12" v-for="(details, index) in pakageDetails" :key="index">
                    <div class="subscription-main" :class="{ 'active': index === 0 }">
                        <div class="subscrip-iner">
                            <div class="subscription-header">
                                <h3 v-if="pakageDetails.length > 0">{{ details.packageName }}</h3>
                                <h6>Advanced collaboration for individuals and organizations</h6>
                                <div class="subscrip-hder-price">
                                    <div class="subs-price-main">
                                        <sup v-if="pakageDetails.length > 0">{{ details.currency }}</sup>
                                        <h2 v-if="pakageDetails.length > 0">{{ details.price }}</h2>
                                    </div>
                                </div>
                                <p>per user/month <br> for the first 12 months <span class="pera-blue-star">*</span></p>
                                <i class="fa-solid fa-star-of-life"></i>
                                <p>Trial Period for <strong>{{ trialLimit }}</strong> days</p>

                                <button type="button" @click="callCheckout(details)">Continue with Team</button>

                                <!-- <button type="button" @click="callCheckout(details)" :disabled="index !== 0">Continue with Team</button> -->
                            </div>
                            <div class="subscription-list">
                                <span v-html="details.description"></span>
                                <!-- <ul class="list-inline">
                                    <li>
                                    <i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>Access to github codespaces</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>Protected branches</span>
                                        </span>
                                        
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>Multiple reviewers in pull requests</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>Draft pull requests</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>Code owners</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>Required reviewers</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i>
                                        <span class="subs-list-spaner">
                                            <span> Pages and Wikis</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i>
                                        <span class="subs-list-spaner">
                                            <span>Environment deployment branches and secrets</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i>
                                        <span class="subs-list-spaner">
                                            <span>3,000 CI/CD minutes/month</span>
                                            <span class="subs-sub-title">Free for public repositories</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>2GB of Packages storage</span>
                                            <span class="subs-sub-title">Free for public repositories</span>
                                        </span>
                                    </li>
                                    <li><i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>Web-based support</span>
                                        </span>
                                    </li>
                                    <li><h5>Featured add-ons</h5></li>
                                    <li><i class="fa-solid fa-chevron-right"></i> 
                                        <span class="subs-list-spaner">
                                            <span>2GB of Packages storage</span>
                                            <span class="subs-sub-title">Free for public repositories</span>
                                        </span>
                                    </li>
                                    
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <beforeLoginChatbot></beforeLoginChatbot>
</template>

<script>
import { API } from "@/api/api";
import beforeLoginChatbot from '../views/beforeLoginChatbot.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Subscription",
  components: {
            // eslint-disable-next-line vue/no-unused-components
            beforeLoginChatbot
        },
  data() {
    return {
        pakageDetails: [],
        isLoading: false,
        trialLimit: null
    };
  },
  async created() {
    // Accessing localStorage when the component is created
    this.getPakageDetails();
    this.getTrialDate();
  },
  methods: {
    getPakageDetails(){
        var head = {
            headers: {
                "x-access-token": JSON.parse(localStorage.getItem("Token"))
            }
          };
        API.get("packages/list", head)
      .then(response => {
                this.pakageDetails = response.data.data;
                console.log(this.pakageDetails, "After got PakageDetails");
            }).catch(error => {
                  console.error("fail", error);
                  });
    },
    callCheckout(pakageDetails){
        this.isLoading = true;
        const obj = {
            price_id: pakageDetails.packageSlug
        };
        var head = {
            headers: {
                "x-access-token": JSON.parse(localStorage.getItem("Token"))
            }
          };
        API.post("subscription/createsubscription", obj, head)
      .then(response => {
                console.log(response, "After call createsubscription");
                const redirectUrl = response.data.data;
                this.isLoading = false;
                window.location.href = redirectUrl;
            }).catch(error => {
                  console.error("fail", error);
                  this.isLoading = false;
                  });
    },
    getTrialDate(){
        var head = {
            headers: {
                "x-access-token": JSON.parse(localStorage.getItem("Token"))
            }
          };
      API.get(`trialperiod`, head)
        .then(response => {
          console.log(response, "Trial management details")
          this.trialLimit = response.data.data.trialLimit;
        
        })
        .catch(error => {
            console.error('Error fetching Trial management:', error);
        });
    }
  }
};
</script>
